import { MembershipApi, OrdersOrderType } from '@wix/ambassador-membership-api/http';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import { PurchaseLimitExceededError } from '../utils/errors';
import { ApiHeaders } from './headers';

type MembershipApiT = ReturnType<typeof MembershipApi>;
export type OrdersService = ReturnType<MembershipApiT['OrdersService']>;

export class OrdersApi {
  constructor(protected headers: ApiHeaders, protected ordersService: OrdersService) {}

  async getOrder(id: string) {
    const { order } = await this.ordersService(this.headers).getOrder({ id });
    return order;
  }

  public async createOrder(plan: PublicPlan, memberId: string) {
    const ordersService = this.ordersService(this.headers);

    const { purchaseLimitExceeded } = await ordersService.getPurchasePreview({
      memberId,
      planId: plan.id,
      validFrom: new Date().toISOString(),
      orderType: OrdersOrderType.ONLINE,
    });
    if (purchaseLimitExceeded) {
      throw new PurchaseLimitExceededError();
    }

    const { orderId } = await ordersService.purchasePlan({ planId: plan.id });
    const { order } = await ordersService.getOrder({ id: orderId });
    return order;
  }

  public updateOrderValidFrom(orderId: string, validFrom: Date) {
    const ordersService = this.ordersService(this.headers);
    return ordersService.updateValidFrom({
      id: orderId,
      validFrom: validFrom.toISOString(),
    });
  }

  public async getDetailedPrice(planId: string) {
    const { price } = await this.ordersService(this.headers).getPricePreview({ planId });
    return price;
  }
}

export function createOrdersApi(headers: ApiHeaders, baseUrl: string = '') {
  return new OrdersApi(headers, MembershipApi(baseUrl + '/_api/paid-plans').OrdersService());
}
